import { Play } from '@entertainment-ai/react-component-library';
import Hls from 'hls.js';
import { MouseEventHandler, useCallback, useEffect, useRef, useState } from 'react';

import { CLIENT_CLASSNAMES } from '@/constants';
import { isColorDark } from '@/utils';

import { NormalizedVideoItem } from '../normalize-data';

import * as Styled from './VerticalGridItem.styles';

export const IMAGE_CONTAINER_CLASSNAME = 'slide-image-container';

const DESKTOP_RESOLUTION = 1024;

interface VerticalGridItemProps {
  onClick: MouseEventHandler;
  item: NormalizedVideoItem;
}

const VerticalGridItem = ({ onClick, item }: VerticalGridItemProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const hls = useRef<Hls | null>(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > DESKTOP_RESOLUTION);
  const [isDarkBackground, setIsDarkBackground] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > DESKTOP_RESOLUTION);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // for auto-detecting the background of the carousel
  // and adjusting the color for titles
  useEffect(() => {
    const findBackgroundColor = (element: HTMLElement): string | null => {
      let currentElement: HTMLElement | null = element;
      while (currentElement) {
        const bgColor = window
          .getComputedStyle(currentElement)
          .getPropertyValue('background-color');
        if (
          bgColor &&
          bgColor !== 'rgba(0, 0, 0, 0)' &&
          bgColor !== 'transparent' &&
          bgColor !== ''
        ) {
          return bgColor;
        }
        currentElement = currentElement.parentElement;
      }
      // If no valid background color is found up the DOM tree, use a default color
      return 'white'; // You can change this to any default color you prefer
    };

    const bgElement = document.querySelector('.seeen-widget-carousel'); // You might need to adjust the selector based on your HTML structure
    if (bgElement) {
      // @ts-ignore
      const bgColor = findBackgroundColor(bgElement);
      // @ts-ignore
      setIsDarkBackground(isColorDark(bgColor));
    }
  }, []);

  const handleHover = useCallback(() => {
    if (isDesktop) {
      setIsHovered(true);
      if (Hls.isSupported()) {
        hls.current = new Hls();
        hls.current.loadSource(item.videoFileUrl);
        hls.current.attachMedia(videoRef.current!);
      } else if (videoRef.current!.canPlayType('application/vnd.apple.mpegurl')) {
        videoRef.current!.src = item.videoFileUrl;
      }
    }
  }, [isDesktop, item.videoFileUrl]);

  const handleHoverLeave = useCallback(() => {
    setIsHovered(false);
    if (videoRef.current) {
      videoRef.current.pause();
    }

    // in case we wanna destroy the player on-leave
    // if (hls.current) {
    //     hls.current.destroy();
    // }
  }, []);

  return (
    <Styled.VerticalGridItemContainer onClick={onClick}>
      <Styled.SlideTitle
        className={CLIENT_CLASSNAMES.carouselSlideLabel}
        style={{
          color: isDarkBackground ? 'white' : 'black', // Choose text color based on background darkness
          transition: 'color 0.3s ease',
        }}
      >
        {item.title}
      </Styled.SlideTitle>
      <Styled.ImageContainer
        className={IMAGE_CONTAINER_CLASSNAME}
        onMouseEnter={handleHover}
        onMouseLeave={handleHoverLeave}
      >
        <Styled.PlayButton>
          <Play width={48} height={80} />
        </Styled.PlayButton>

        <Styled.ContentContainer>
          {isDesktop && (
            <Styled.VideoOnHover ref={videoRef} width="100%" height="100%" autoPlay loop muted>
              Your browser does not support the video tag.
            </Styled.VideoOnHover>
          )}

          <Styled.Thumbnail
            url={item.thumbnailUrl}
            style={
              isDesktop
                ? {
                    opacity: isHovered ? 0 : 1,
                    transition: isHovered
                      ? 'opacity 0.5s ease 0.5s' // 0.5s delay when hovering over
                      : 'opacity 0.3s ease 0.1s', // 0.1s delay when leaving
                  }
                : undefined
            }
          />
        </Styled.ContentContainer>
      </Styled.ImageContainer>
    </Styled.VerticalGridItemContainer>
  );
};

export default VerticalGridItem;
