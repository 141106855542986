import { th } from '@entertainment-ai/react-component-library';
import styled from 'styled-components';

import { Nullable } from '@/types';

const CONTENT_HEIGHT = '550px';

export const VerticalGridItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  //* if flexbox instead of grid is needed with fixed width
  // width: 300px;
`;

export const PlayButton = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  color: ${th('bg')};
  z-index: 9;
`;

export const ImageContainer = styled.div`
  position: relative;
`;

export const Image = styled.img`
  border-radius: 7px;
  max-height: 201px;
  width: 100%;
`;

export const ImageSvg = styled.svg`
  border-radius: 15px;
  max-height: 201px;
  width: 100%;
`;

interface ThumbnailProps {
  url?: Nullable<string>;
}

export const ContentContainer = styled.div`
  width: 100%;
  height: ${CONTENT_HEIGHT};
  position: relative;
`;

export const Thumbnail = styled.div.attrs<ThumbnailProps>(({ url }) => ({
  style: url ? { backgroundImage: `url(${url})` } : { backgroundColor: '#000' },
}))<ThumbnailProps>`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  overflow: hidden;
  width: 100%;
  height: ${CONTENT_HEIGHT};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const VideoOnHover = styled.video`
  height: ${CONTENT_HEIGHT};
  width: 100%;
  display: block;
  object-fit: cover;
`;

export const SlideTitle = styled.h5`
  line-height: 20px;
  word-wrap: break-word;
  overflow: hidden;
  text-align: center;
  margin: 10px 0;
  color: ${th('titleColor')};
  font-size: 12px;
`;
