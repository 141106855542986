import { ComponentPropsWithoutRef } from 'react';

type Props = ComponentPropsWithoutRef<'svg'>;

export function CloseIcon(props: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={10} height={10} {...props}>
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M8.502 1.431l-7.07 7.071m7.095.042L1.463 1.466"
      />
    </svg>
  );
}
