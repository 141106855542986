import { gql } from 'graphql-request';
import { useQuery } from 'react-query';

import { useGraphQLClient } from '@/graphql-client';
import { Nullable } from '@/types';

import {
  END_CARD_FRAGMENT,
  normalizeItem,
  PLAYLIST_ITEM_FRAGMENT,
  PlaylistInterface,
  PROMOTE_RESPONSE_FRAGMENT,
} from './useCarouselData';

const QUERY = gql`
  ${PROMOTE_RESPONSE_FRAGMENT}
  ${END_CARD_FRAGMENT}
  ${PLAYLIST_ITEM_FRAGMENT}

  query GetPlaylistItemById($playlistId: ID!, $itemId: ID!) {
    publicPlaylistById(id: $playlistId) {
      id
      name
      itemById(id: $itemId) {
        ...PlaylistItem
      }
    }
  }
`;

interface Response {
  publicPlaylistById: Nullable<Pick<PlaylistInterface, 'id' | 'name' | 'itemById'>>;
}

interface Variables {
  playlistId: string;
  itemId?: string;
}

export function useCarouselItemData(variables: Variables) {
  const client = useGraphQLClient();
  const query = useQuery({
    queryKey: ['carousel-item', variables],
    queryFn: () => client.request<Response, Variables>(QUERY, variables),
    staleTime: Infinity,
    enabled: !!variables.itemId,
  });

  return {
    ...query,
    item: normalizeItem(query.data?.publicPlaylistById?.itemById),
  };
}
