import {
  VideoItemType,
  VideoJSPlayerContextProvider,
} from '@entertainment-ai/react-component-library';
import React from 'react';
import { useSwipeable } from 'react-swipeable';

import { NormalizedVideoItem } from '@/features/normalize-data';
import { PlayerContainer } from '@/features/player-widget/PlayerContainer';
import { useWindowSize } from '@/hooks';
import { CloseIcon } from '@/icons';

import { useGoogleAnalyticsEx } from '../analytics';
import { WidgetConfiguration } from '../widget-preset';
import { LightboxNavigation } from './LightboxNavigation';

import * as Styled from './LightboxPlayer.styles';

interface LightboxPlayerProps {
  items: NormalizedVideoItem[];
  index: number;
  onClose: () => void;
  openNext: () => void;
  openPrevious: () => void;
  configuration: WidgetConfiguration;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export function VerticalLightboxPlayer({
  items,
  index,
  onClose,
  openNext,
  openPrevious,
  configuration,
  hasNextPage,
  hasPreviousPage,
}: LightboxPlayerProps) {
  const currentItem = items[index];
  const canShowArrows = items.length > 1;
  const windowSize = useWindowSize();

  const trackItem = useGoogleAnalyticsEx('Vertical theme playlist');

  const handleClickOnBackground = (e: React.MouseEvent) => {
    if (e.currentTarget === e.target) {
      trackItem('closeLightbox', { label: 'Close by clicking on empty space' });
      onClose();
    }
  };

  const swipeHandlers = useSwipeable({
    onSwipedUp: () => {
      if (hasNextPage) {
        trackItem('swipeLeft', { label: 'Swipe to next video' });
        openNext();
      }
    },
    onSwipedDown: () => {
      if (hasPreviousPage) {
        trackItem('swipeRight', { label: 'Swipe to prev video' });
        openPrevious();
      }
    },
    // We can add more handlers for other directions if needed
  });

  const firstFocusableElementRef = React.useRef<HTMLButtonElement>(null);
  const { focusTrap } = useFocusTrapEvents(firstFocusableElementRef, [index !== undefined]);

  const videoAspectRatio =
    currentItem?.type === VideoItemType.Moment
      ? currentItem?.video?.aspectRatio
      : currentItem?.aspectRatio;

  const calculateRatio = (aspectRatio?: string): number | null => {
    if (!aspectRatio) return null;
    const ratioArr = aspectRatio.split(':');
    return Number(ratioArr[0]) / Number(ratioArr[1]);
  };

  const ratio = calculateRatio(videoAspectRatio);

  const videoStyles = {
    width: windowSize.width > 480 && ratio && ratio > 1 ? '50%' : '100%',
    margin: '0 auto',
  };

  return currentItem ? (
    <Styled.LightboxWrapper
      onKeyDown={(event: React.KeyboardEvent) => {
        if (event.key === 'Escape') {
          trackItem('closeLightbox', { label: 'Close by clicking ESC button' });
          onClose();
        }
      }}
      {...swipeHandlers}
    >
      {focusTrap}
      <Styled.LightboxContainer onClick={(e: React.MouseEvent) => handleClickOnBackground(e)}>
        <Styled.LightboxClose
          ref={firstFocusableElementRef}
          onClick={() => {
            trackItem('closeLightbox', { label: 'Close by clicking close button' });
            onClose();
          }}
          aria-label="Close"
        >
          <CloseIcon className="lightbox-close-icon" />
        </Styled.LightboxClose>
        {canShowArrows && (
          <LightboxNavigation
            nextMomentHandler={() => {
              trackItem('click', { label: 'Open next video with arrow' });
              openNext();
            }}
            prevMomentHandler={() => {
              trackItem('click', { label: 'Open prev video with arrow' });
              openPrevious();
            }}
            hasNextArrow={hasNextPage}
            hasPreviousArrow={hasPreviousPage}
          />
        )}
        <Styled.LightboxContent verticalVideo={videoAspectRatio !== '16:9'}>
          <Styled.ContainerPins>
            <Styled.ContainerRadius>
              {/* key is needed to reload the component with fresh state */}
              <VideoJSPlayerContextProvider key={currentItem.id}>
                <PlayerContainer
                  videoItem={currentItem}
                  configuration={configuration}
                  autoplay
                  hasPortraitMode
                  style={videoStyles}
                />
              </VideoJSPlayerContextProvider>
            </Styled.ContainerRadius>
          </Styled.ContainerPins>
        </Styled.LightboxContent>
      </Styled.LightboxContainer>
      {focusTrap}
    </Styled.LightboxWrapper>
  ) : null;
}

function useFocusTrapEvents(
  firstFocusableElementRef: React.RefObject<HTMLElement>,
  focusDeps: any[],
) {
  const [isFocusInsideTrap, setIsFocusInsideTrap] = React.useState(false);

  React.useLayoutEffect(() => {
    if (!isFocusInsideTrap) {
      // eslint-disable-next-line no-unused-expressions
      firstFocusableElementRef.current?.focus();
      setIsFocusInsideTrap(true);
    }
  }, [firstFocusableElementRef, isFocusInsideTrap, ...focusDeps]);

  React.useEffect(() => {
    return () => {
      setIsFocusInsideTrap(false);
    };
  });

  const focusTrap = React.useMemo(
    () => (
      <div
        style={{ position: 'absolute', opacity: 0 }}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        onFocus={() => {
          // eslint-disable-next-line no-unused-expressions
          firstFocusableElementRef.current?.focus();
        }}
      />
    ),
    [firstFocusableElementRef],
  );

  return {
    focusTrap,
  };
}
