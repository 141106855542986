import 'slick-carousel/slick/slick.css';

import { GoArrow } from '@entertainment-ai/react-component-library';
import { Dispatch, MouseEvent, SetStateAction, useLayoutEffect, useRef, useState } from 'react';
import Slider, { Settings } from 'react-slick';

import { useGoogleAnalyticsEx } from '@/features/analytics';
import { NormalizedVideoItem } from '@/features/normalize-data';

import { CarouselItem, IMAGE_CONTAINER_CLASSNAME } from './CarouselItem';
import VerticalGridItem from './VerticalGridItem';

import * as Styled from './VerticalCarousel.styles';

const MOBILE_SIZE = 1;
const TABLET_SIZE = 2;
const TABLET_SIZE_GRID = 6;
const DESKTOP_SIZE = 3;
const DESKTOP_SIZE_GRID = 10;
const TABLET_BREAKPOINT = 450;
const DESKTOP_BREAKPOINT = 992;

export function getCarouselPageSize(showGrid: boolean, pageWidth: number) {
  const desktopSize = showGrid ? DESKTOP_SIZE_GRID : DESKTOP_SIZE;
  const tabletSize = showGrid ? TABLET_SIZE_GRID : TABLET_SIZE;
  const mobileSize = showGrid ? TABLET_SIZE_GRID : MOBILE_SIZE;
  // eslint-disable-next-line no-nested-ternary
  return pageWidth > DESKTOP_BREAKPOINT
    ? desktopSize
    : pageWidth > TABLET_BREAKPOINT
    ? tabletSize
    : mobileSize;
}

const SLICK_SETTINGS: Settings = {
  draggable: false,
  dots: false,
  infinite: false,
  speed: 300,
  slidesToShow: DESKTOP_SIZE,
  slidesToScroll: DESKTOP_SIZE,
  responsive: [
    {
      breakpoint: DESKTOP_BREAKPOINT,
      settings: {
        slidesToShow: TABLET_SIZE,
        slidesToScroll: TABLET_SIZE,
      },
    },
    {
      breakpoint: TABLET_BREAKPOINT,
      settings: {
        slidesToShow: MOBILE_SIZE,
        slidesToScroll: MOBILE_SIZE,
      },
    },
  ],
  arrows: false,
};

interface Props {
  items: NormalizedVideoItem[];
  onItemClick: (index: number) => void;
  loadMoreItems: () => Promise<unknown>;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  setCarouselItemIndex: Dispatch<SetStateAction<number>>;
  isFetching: boolean;
  showGrid: boolean;
}

export function Carousel({
  items,
  onItemClick,
  loadMoreItems,
  hasNextPage,
  hasPreviousPage,
  setCarouselItemIndex,
  isFetching,
  showGrid,
}: Props) {
  const sliderRef = useRef<Slider>(null);

  const [imageHeight, setImageHeight] = useState(0);
  const updateImageHeight = () => {
    // @ts-expect-error: invalid react-slick types
    const container = sliderRef.current?.innerSlider.list.childNodes[0]?.querySelector(
      `.${IMAGE_CONTAINER_CLASSNAME}`,
    );

    if (container) {
      setImageHeight(container.offsetHeight);
    }
  };

  useLayoutEffect(() => {
    window.addEventListener('resize', updateImageHeight);
    return () => {
      window.removeEventListener('resize', updateImageHeight);
    };
  }, []);

  const settings: Settings = {
    ...SLICK_SETTINGS,
    onReInit: updateImageHeight,
    afterChange: (currentSlide) => setCarouselItemIndex(currentSlide),
  };

  const trackItemClick = useGoogleAnalyticsEx('Vertical theme playlist');

  const handleCarouselItemClick = (event: MouseEvent, index: number, title: string) => {
    event.preventDefault();

    trackItemClick('click', { label: `Open lightbox. Video - ${title}` });
    onItemClick(index);
  };

  return (
    <Styled.CarouselContainer>
      {showGrid ? (
        <Styled.CarouselGridWrapper>
          {items.map((item, index) => (
            <VerticalGridItem
              key={item.id}
              item={item}
              onClick={(event) => handleCarouselItemClick(event, index, item.title)}
            />
          ))}
        </Styled.CarouselGridWrapper>
      ) : (
        <Styled.CarouselWrapper>
          {hasPreviousPage && (
            <Styled.CarouselPrevArrow
              onClick={() => {
                sliderRef.current?.slickPrev();
              }}
              imageHeight={imageHeight}
              aria-label="Go to previous moments in slider"
            >
              <GoArrow className="prev-arrow-icon" />
            </Styled.CarouselPrevArrow>
          )}
          <Styled.CarouselContent>
            <Styled.CarouselSlider>
              <Slider
                ref={sliderRef}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...settings}
              >
                {items.map((item, index) => (
                  <CarouselItem
                    key={item.id}
                    item={item}
                    onClick={(event) => handleCarouselItemClick(event, index, item.title)}
                  />
                ))}
              </Slider>
            </Styled.CarouselSlider>
          </Styled.CarouselContent>
          {hasNextPage && (
            <Styled.CarouselNextArrow
              onClick={() => {
                if (isFetching) return;

                loadMoreItems().then(() => {
                  sliderRef.current?.slickNext();
                });
              }}
              imageHeight={imageHeight}
              aria-label="Go to next moments in slider"
            >
              <GoArrow className="next-arrow-icon" />
            </Styled.CarouselNextArrow>
          )}
        </Styled.CarouselWrapper>
      )}
    </Styled.CarouselContainer>
  );
}
