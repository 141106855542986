import { gql } from 'graphql-request';
import { useInfiniteQuery } from 'react-query';

import { PlaylistContentType } from '@/api-types';
import {
  MomentItemPick,
  NormalizedVideoItem,
  normalizeMoment,
  normalizeVideo,
  VideoItemPick,
} from '@/features/normalize-data';
import { useGraphQLClient } from '@/graphql-client';
import { Nullable } from '@/types';
import { notEmpty } from '@/utils';

export const END_CARD_FRAGMENT = gql`
  fragment EndCards on PublicEndCardType {
    id
    callToActionButton {
      id
      label
      icon
      url
    }
    promoCodeButton {
      id
      label
      text
    }
    watchNextButtonVisible
    socialMediaButtons {
      platformName
      accountUrl
      label
      id
    }
    cardType
    origin
    customCode
    background
    border
    id
    headline
    subline
    videoControlButtons
    cardSize
    backgroundImageUrl
    overlay
    cardOrientation
    backgroundImageSettings
    ctaButton1 {
      name
      id
      text
      url
      background
      border
      icon
    }
    ctaButton2 {
      name
      id
      text
      url
      background
      border
      icon
    }
  }
`;

export const PROMOTE_RESPONSE_FRAGMENT = gql`
  fragment PromotedContentFragment on VideoPromotedContentSettingsType {
    promoteObjects
    promoteAtStart
    promoteAtEnd
    promotedObjectsLabel
  }
`;

export const PLAYLIST_ITEM_FRAGMENT = gql`
  fragment PlaylistItem on PlaylistItem {
    ... on PublicVideoType {
      id
      duration
      slug
      structuredData
      thumbnailUrl
      title
      captions {
        language
        languageCode
        captionFileUrl
      }
      videoFileUrl
      videoStreams {
        standard
        manifestFileUrl
      }
      aspectRatio
      endCard {
        ...EndCards
      }
      promotedContentSettings {
        ...PromotedContentFragment
      }
      objects {
        title
        url
        promote
        id
        imageUrl
      }
      moments {
        id
        title
        duration
        startTimestamp
        endTimestamp
        thumbnailUrl
        endCard {
          ...EndCards
        }
      }
      monetization {
        orgMonetization {
          id
          active
          default
          monetizationUrl
          name
        }
        useDefaultMonetization
        isMonetizationEnabled
        adType
        adDensity
        adLocation
      }
      ctaTimestamps {
        id
        name
        startTimestamp
        endTimestamp
        ctas {
          id
          button {
            id
            name
            url
            text
            background
            border
            icon
          }
          image {
            id
            name
            url
            imageUrl
          }
          config
          order
        }
      }
    }
    ... on PublicMomentType {
      id
      startTimestamp
      endTimestamp
      duration
      slug
      structuredData
      thumbnailUrl
      title
      endCard {
        ...EndCards
      }
      video {
        id
        duration
        title
        slug
        thumbnailUrl
        captions {
          language
          languageCode
          captionFileUrl
        }
        aspectRatio
        videoFileUrl
        videoStreams {
          standard
          manifestFileUrl
        }
        promotedContentSettings {
          ...PromotedContentFragment
        }
        objects {
          id
          title
          category
          url
          imageUrl
          promote
        }
        monetization {
          orgMonetization {
            id
            active
            default
            monetizationUrl
            name
          }
          useDefaultMonetization
          isMonetizationEnabled
          adType
          adDensity
          adLocation
        }
        ctaTimestamps {
          id
          name
          startTimestamp
          endTimestamp
          ctas {
            id
            button {
              id
              name
              url
              text
              background
              border
              icon
            }
            image {
              id
              name
              url
              imageUrl
            }
            config
            order
          }
        }
      }
    }
  }
`;

const QUERY = gql`
  ${PROMOTE_RESPONSE_FRAGMENT}
  ${END_CARD_FRAGMENT}
  ${PLAYLIST_ITEM_FRAGMENT}

  query GetPlaylistById(
    $playlistId: ID!
    $playerLocationUrl: URL!
    $page: Int
    $pageSize: Int
    $listItemUrlItemIdParam: String
  ) {
    publicPlaylistById(id: $playlistId) {
      id
      name
      items(page: $page, pageSize: $pageSize) {
        totalCount
        pageInfo {
          hasNextPage
        }
        nodes {
          ...PlaylistItem
        }
      }
      contentType
      structuredData(
        playerLocationUrl: $playerLocationUrl
        listItemUrlItemIdParam: $listItemUrlItemIdParam
      )
    }
  }
`;

export type PlaylistItemType = VideoItemPick | MomentItemPick;

export interface PlaylistInterface {
  id: string;
  name: string;
  itemById: Nullable<PlaylistItemType>;
  items: {
    totalCount: number;
    pageInfo: Nullable<{
      hasNextPage: boolean;
    }>;
    nodes: PlaylistItemType[];
  };
  contentType: PlaylistContentType;
  structuredData: string;
}

interface Response {
  publicPlaylistById: Nullable<
    Pick<PlaylistInterface, 'id' | 'name' | 'items' | 'contentType' | 'structuredData'>
  >;
}

interface Variables {
  playlistId: string;
  page: number;
  pageSize: number;
  playerLocationUrl: string;
  listItemUrlItemIdParam: string;
}

export function useCarouselData(variables: Variables) {
  const client = useGraphQLClient();
  const query = useInfiniteQuery({
    queryKey: ['carousel', variables.playlistId],
    queryFn: ({ pageParam: page }) => {
      const computedVariables = {
        ...variables,
        page: typeof page !== 'undefined' ? page : variables.page,
      };
      return client.request<Response, Variables>(QUERY, computedVariables);
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const data = query.data?.pages[variables.page - 1];
  const items = query.data?.pages.reduce((allPages, page) => {
    return allPages.concat(page.publicPlaylistById?.items.nodes ?? []);
  }, [] as PlaylistItemType[]);

  return {
    ...query,
    data: data?.publicPlaylistById,
    items: (items || []).map(normalizeItem).filter(notEmpty),
  };
}

export function normalizeItem(item: Nullable<PlaylistItemType>): NormalizedVideoItem | undefined {
  if (!item) return undefined;

  if ('video' in item) {
    return normalizeMoment(item);
  }

  return normalizeVideo(item);
}
