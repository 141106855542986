import { ComponentPropsWithoutRef } from 'react';

type Props = ComponentPropsWithoutRef<'svg'>;

export function GoArrow(props: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={6} height={10} {...props}>
      <path
        d="M1 9l4-4-4-4"
        stroke="currentColor"
        strokeWidth={2}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
