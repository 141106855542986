import { Play } from '@entertainment-ai/react-component-library';
import { MouseEventHandler } from 'react';

import { CLIENT_CLASSNAMES } from '@/constants';
import { NormalizedVideoItem } from '@/features/normalize-data';

import * as Styled from './VerticalCarousel.styles';

export const IMAGE_CONTAINER_CLASSNAME = 'slide-image-container';

interface Props {
  onClick: MouseEventHandler;
  item: NormalizedVideoItem;
}

export function CarouselItem({ onClick, item }: Props) {
  return (
    <Styled.Slide onClick={onClick}>
      <Styled.ImageContainer className={IMAGE_CONTAINER_CLASSNAME}>
        <Styled.PlayButton>
          <Play width={48} height={80} />
        </Styled.PlayButton>
        <Styled.Thumbnail url={item.thumbnailUrl} />
      </Styled.ImageContainer>
      <Styled.SlideTitle className={CLIENT_CLASSNAMES.carouselSlideLabel}>
        {item.title}
      </Styled.SlideTitle>
    </Styled.Slide>
  );
}
