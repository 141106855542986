import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { CLIENT_CLASSNAMES } from '@/constants';
import { GoogleAnalyticsProvider } from '@/features/analytics';
import { ThemeProvider } from '@/features/theme';
import { VerticalLightboxPlayer } from '@/features/vertical-lightbox-player';
import { mergeOptionsWithPreset, useWidgetPreset } from '@/features/widget-preset';
import { InlineOptions, WidgetComponentProps, WidgetType } from '@/types';
import { STRUCTURED_DATA_PLAYLIST_ITEM_ID_PARAM, WIDGET_ID_PARAM } from '@/utils';

import { StructuredDataContainer } from '../structured-data';
import { Carousel, getCarouselPageSize } from './Carousel';
import { SingleItemPlayer } from './SingleItemPlayer';
import { useCarouselData } from './useCarouselData';
import { useCarouselItemData } from './useCarouselItemData';

import * as Styled from './VerticalCarousel.styles';

export function VerticalCarouselWidget({ widgetId, options }: WidgetComponentProps) {
  const playerLocationUrl = useMemo(() => {
    const url = new URL(window.location.href);
    // remove query param that is automatically injected by API
    url.searchParams.delete(STRUCTURED_DATA_PLAYLIST_ITEM_ID_PARAM);
    url.searchParams.set(WIDGET_ID_PARAM, widgetId);
    return url.toString();
  }, []);

  const { itemIdToOpen, clearOpenId } = useItemIdToOpen(widgetId);

  if (!options.playlistId) {
    return null;
  }

  return (
    <CarouselWidgetInner
      id={options.playlistId}
      options={options}
      playerLocationUrl={playerLocationUrl}
      itemIdToOpen={itemIdToOpen}
      clearOpenId={clearOpenId}
    />
  );
}

interface CarouselWidgetInnerProps {
  id: string;
  options: InlineOptions;
  playerLocationUrl: string;
  itemIdToOpen?: string;
  clearOpenId: () => void;
}

function CarouselWidgetInner({
  id,
  options,
  playerLocationUrl,
  itemIdToOpen,
  clearOpenId,
}: CarouselWidgetInnerProps) {
  // we don't support page resizes for this component

  const [showGridInsteadOfCarousel] = useState(true);
  const CAROUSEL_PAGE_SIZE = getCarouselPageSize(showGridInsteadOfCarousel, window.innerWidth);

  const [pageNumber, setPageNumber] = useState(1);
  const [carouselItemIndex, setCarouselItemIndex] = useState(0);
  const { data: carouselData, items, fetchNextPage, isFetching } = useCarouselData({
    playlistId: id,
    page: pageNumber,
    pageSize: CAROUSEL_PAGE_SIZE,
    playerLocationUrl,
    listItemUrlItemIdParam: STRUCTURED_DATA_PLAYLIST_ITEM_ID_PARAM,
  });
  const [isFetchingNextPage, setIsFetchingNextPage] = useState(false);
  const [hasMorePages, setHasMorePages] = useState(true);

  const { item: itemToOpen } = useCarouselItemData({
    playlistId: id,
    itemId: itemIdToOpen,
  });
  const { data: presetData } = useWidgetPreset(options.presetId);

  const configuration = {
    ...mergeOptionsWithPreset(options, presetData),
    showThumbnailsSlider: false,
    showMomentsBelowVideo: false,
  };

  const {
    currentItemIndex,
    openItemAtIndex,
    openNextItem,
    openPreviousItem,
    onItemClose,
    isOpened,
  } = useLightboxItemsState(items.length);

  const currentCarouselPage = carouselItemIndex / CAROUSEL_PAGE_SIZE + 1;
  const canLoadMoreData = (index: number, pageSize: number) =>
    (carouselData?.items.totalCount ?? 0) > Math.ceil(index) * pageSize;

  const intersectionRef = useRef(null);
  const testRef = useRef(null);

  const loadMoreItemsCarousel = () => {
    return fetchNextPage({
      pageParam: pageNumber + 1,
    }).then((response) => {
      setPageNumber((n) => n + 1);
      return response;
    });
  };

  const loadMoreItemsGrid = () => {
    if (!isFetchingNextPage && hasMorePages) {
      setIsFetchingNextPage(true);

      fetchNextPage({ pageParam: pageNumber + 1 }).then(() => {
        setPageNumber((prevPageNumber) => prevPageNumber + 1);
        setIsFetchingNextPage(false);

        if (!carouselData?.items.pageInfo?.hasNextPage) {
          setHasMorePages(false);
        }
      });
    }
  };

  const loadMoreItemsForCarousel = () => {
    // Do an early return, since we've loaded all needed data already
    if (currentCarouselPage * CAROUSEL_PAGE_SIZE < items.length) {
      return Promise.resolve();
    }

    // Load more data
    if (carouselData?.items.pageInfo?.hasNextPage) {
      return loadMoreItemsCarousel();
    }

    return Promise.resolve();
  };

  const loadMoreItemsForPlayer = () => {
    // Do an early return, since we've loaded all needed data already
    if (currentItemIndex + 1 < items.length) {
      return Promise.resolve();
    }

    // Load more data
    if (carouselData?.items.pageInfo?.hasNextPage) {
      return loadMoreItemsCarousel();
    }

    return Promise.resolve();
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            loadMoreItemsGrid();
          }
        });
      },
      { threshold: 1 }, // Trigger when the entire section is visible
    );

    if (intersectionRef.current) {
      observer.observe(intersectionRef.current);
    }

    return () => {
      if (intersectionRef.current) {
        observer.unobserve(intersectionRef.current);
      }
    };
  }, [loadMoreItemsGrid]);

  return carouselData ? (
    <GoogleAnalyticsProvider
      clientTrackingId={configuration.trackingId}
      clientMeasurementId={configuration.measurementId}
      widgetType={WidgetType.VerticalCarousel}
    >
      <ThemeProvider mainColor={configuration.mainColor} theme={configuration.theme}>
        {!configuration.disableStructuredData ? (
          <StructuredDataContainer structuredData={carouselData.structuredData} />
        ) : null}
        <Styled.CarouselTitle className={CLIENT_CLASSNAMES.widgetTitle} ref={testRef}>
          {carouselData.name}
        </Styled.CarouselTitle>
        <Carousel
          items={items}
          onItemClick={openItemAtIndex}
          loadMoreItems={loadMoreItemsForCarousel}
          hasNextPage={canLoadMoreData(currentCarouselPage, CAROUSEL_PAGE_SIZE)}
          hasPreviousPage={carouselItemIndex > 0}
          setCarouselItemIndex={setCarouselItemIndex}
          isFetching={isFetching}
          showGrid={showGridInsteadOfCarousel}
        />
        {itemToOpen ? (
          <SingleItemPlayer item={itemToOpen} onClose={clearOpenId} configuration={configuration} />
        ) : null}
        {isOpened && (
          <VerticalLightboxPlayer
            items={items}
            index={currentItemIndex}
            openNext={() => {
              if (isFetching) return;

              loadMoreItemsForPlayer().then((response) => {
                if (response) {
                  const totalCount = response.data?.pages.reduce((allPages, page) => {
                    return allPages + (page.publicPlaylistById?.items.nodes.length ?? 0);
                  }, 0);
                  openNextItem(totalCount);
                } else {
                  openNextItem();
                }
              });
            }}
            openPrevious={openPreviousItem}
            onClose={onItemClose}
            configuration={configuration}
            hasNextPage={canLoadMoreData(currentItemIndex + 1, 1)}
            hasPreviousPage={currentItemIndex > 0}
          />
        )}
      </ThemeProvider>
      <div ref={intersectionRef} style={{ height: '1px', width: '1px' }}></div>
    </GoogleAnalyticsProvider>
  ) : null;
}

function useLightboxItemsState(itemCount: number) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    if (isOpened) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpened]);

  const openPreviousItem = () => {
    const index = (currentIndex + itemCount - 1) % itemCount;
    setCurrentIndex(index);
  };

  const openNextItem = (freshItemCount?: number) => {
    const count = freshItemCount ?? itemCount;
    const index = (currentIndex + 1) % count;
    setCurrentIndex(index);
  };

  const openItemAtIndex = useCallback((index: number) => {
    setCurrentIndex(index);
    setIsOpened(true);
  }, []);

  const onItemClose = useCallback(() => {
    setIsOpened(false);
  }, []);

  return {
    currentItemIndex: currentIndex,
    isOpened,
    onItemClose,
    setCurrentIndex,
    openItemAtIndex,
    openPreviousItem,
    openNextItem,
  };
}

function useItemIdToOpen(widgetId: string) {
  const [itemIdToOpen, setId] = useState(() => {
    const url = new URL(window.location.href);
    const widgetIdParam = url.searchParams.get(WIDGET_ID_PARAM);
    const itemIdParam = url.searchParams.get(STRUCTURED_DATA_PLAYLIST_ITEM_ID_PARAM);

    if (!widgetIdParam || !itemIdParam) return undefined;
    if (widgetIdParam !== widgetId) return undefined;

    return itemIdParam;
  });

  const clearOpenId = useCallback(() => {
    setId(undefined);
  }, []);

  return {
    itemIdToOpen,
    clearOpenId,
  };
}
