import { resetButton, th } from '@entertainment-ai/react-component-library';
import styled, { css } from 'styled-components';

import { Nullable } from '@/types';

export const CarouselContainer = styled.div`
  display: flex; /* improves compatibility */

  .slick-list {
    overflow: visible;
  }

  .slick-slide {
    padding: 0 10px;
    box-sizing: border-box;
  }

  .slick-track {
    display: flex;
  }
`;

export const CarouselWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

export const CarouselContent = styled.div`
  font-family: 'Montserrat', sans-serif;
  ${(props) =>
    props.theme.showSliderMasks &&
    css`
      -webkit-mask-image: linear-gradient(
        270deg,
        transparent 0%,
        black 20%,
        black 88%,
        transparent 100%
      );
    `}
`;

export const CarouselSlider = styled.div`
  width: 82%;
  margin-left: 9%;

  @media screen and (max-width: 455px) {
    margin-left: 20px;
  }
`;

export const Slide = styled.button`
  ${resetButton};
  position: relative;
  width: 100%;
  display: inline-block;
  cursor: pointer;
`;

export const PlayButton = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  color: ${th('bg')};
`;

export const ImageContainer = styled.div`
  position: relative;
`;

export const Image = styled.img`
  border-radius: 7px;
  max-height: 201px;
  width: 100%;
`;

export const ImageSvg = styled.svg`
  border-radius: 15px;
  max-height: 201px;
  width: 100%;
`;

interface ThumbnailProps {
  url?: Nullable<string>;
}

export const Thumbnail = styled.div.attrs<ThumbnailProps>(({ url }) => ({
  style: url ? { backgroundImage: `url(${url})` } : { backgroundColor: '#000' },
}))<ThumbnailProps>`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  height: 0;
  overflow: hidden;
  width: 100%;
  height: 350px;
`;

export const SlideTitle = styled.h5`
  line-height: 20px;
  word-wrap: break-word;
  overflow: hidden;
  text-align: center;
  margin: 10px 0;
  color: ${th('titleColor')};
  font-size: 12px;
`;

interface CarouselArrowProps {
  imageHeight: number;
}

export const CarouselArrow = styled.button.attrs<CarouselArrowProps>(({ imageHeight }) => ({
  style: {
    top: `${imageHeight / 2 + 17}px`,
  },
}))<CarouselArrowProps>`
  border-radius: 8px;
  background-color: ${th('primary')};
  z-index: 5;
  color: #fff;
  position: absolute;
  border: none;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const CarouselNextArrow = styled(CarouselArrow)`
  right: 2%;
  transform: translateY(-100%);
`;

export const CarouselPrevArrow = styled(CarouselArrow)`
  left: 2%;
  transform: translateY(-100%) rotate(-180deg);
`;

export const CarouselTitle = styled.h3`
  margin: 20px 0;
`;

export const CarouselGridWrapper = styled.div`
  //* if flexbox container is needed instead of grid
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: center;
  // align-items: flex-end;
  // gap: 1rem;

  display: grid;
  width: 100%;

  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  grid-gap: 1rem;

  @media screen and (min-width: 992px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media screen and (min-width: 1921px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  @media screen and (min-width: 450px) and (max-width: 991px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media screen and (max-width: 449px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;
